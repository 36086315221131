<template>
  <div class="status">
    <div v-if="active" class="success">Actif</div>
    <div v-else class="danger">Désactif</div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.status {
  line-height: 30px;
  font-weight: 500;
}

.success,
.warning,
.danger,
.opend,
.draft,
.delivre {
  display: flex;
  justify-content: start;
  align-items: center;
  svg {
    width: 14px;
    margin-right: 4px;
  }
}
.success {
  color: rgb(34 197 94);
}
.warning {
  color: rgb(234 179 8);
}
.danger {
  color: rgb(239 68 68);
}
.opend {
  color: rgb(139 92 246);
}
.draft {
  color: rgb(217 70 239);
}
.delivre {
  color: rgb(16 185 129);
}
</style>
