<template>
  <div class="flex flex-col font-Inter px-10 mb-3">
    <Loading v-if="loading" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        ></div>
        <div>
          <el-button icon="el-icon-printer">Imprimer la fiche</el-button>
          <router-link
            class="mx-1"
            :to="`/article/update/${article.article_id}`"
          >
            <el-button icon="el-icon-edit">Modifier l'article</el-button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col mx-auto bg-white p-5 shadow">
      <div class="flex items-center justify-between border-b">
        <div class="flex flex-col">
          <h2
            class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate"
          >
            {{ article.article_name }}
          </h2>
          <div class="mt-1 pb-1 flex items-center">
            <span class="font-light">ID article :</span>
            <span class="text-blue-500">{{ article.sku }}</span>
          </div>

          <div class="mt-1 flex pb-1 items-center">
            <span class="font-light mr-4"> Statut :</span>
            <!--  <div
              v-if="article.active"
              class="px-2py-1 bg-green-100 border font-bold border-green-400 text-green-400 text-xs text-center rounded-md"
            >
              Active
            </div>
            <div
              v-if="!article.active"
              class="px-2 bg-green-100 py-1 border font-bold border-green-400 text-green-400 text-xs text-center rounded-md"
            >
              Active
            </div> -->

            <Active :active="article.is_active" />
          </div>
        </div>
        <div>
          <div class="flex-1 flex flex-col justify-end items-end py-4">
            <div class="text-2xl float-left font-bold">{{ stockOnHand }}</div>
            <div class="text-gray-400 mt-1">Total quantité disponible</div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="flex flex-row items-start justify-between mb-4">
          <div class="flex w-1/5 pr-6">
            <img src="@/assets/notfound.png" alt="" />
          </div>
          <div class="flex flex-1 flex-col W-4/5">
            <div class="flex justify-start">
              <div class="flex flex-col flex-1">
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Date creation:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.date_update }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w w-1/3 text-gray-500"
                    >Date mise à jour:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.date_update }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Groupe:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.group_name }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Categorie:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.category_value }}</span
                  >
                </div>

                <div class="flex flex-col mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Description:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.remark }}
                  </span>
                </div>
              </div>
              <div class="flex flex-1 flex-col justify-evenly">
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Unite de mesure:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.uom_value }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Code barre:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.barcode }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Stock max de securité:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.stock_max }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Stock min de securité:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.stock_min }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Unite de mesure:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.uom_value }}</span
                  >
                </div>
                <div class="flex mb-3">
                  <span class="mr-2 font-light w-1/3 text-gray-500"
                    >Code barre:</span
                  >
                  <span class="text-base font-medium">
                    {{ article.barcode }}</span
                  >
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 flex flex-col justify-center py-4">
                <div class="text-2xl text-gr font-bold">
                  {{ article.selling_price | moneyFilter }} {{user.currency_code}}
                </div>
                <div class="text-blue-500 mt-2">Prix de vente</div>
              </div>

              <div class="flex-1">
                <div class="flex-1 flex flex-col justify-center py-4">
                  <div class="text-2xl text-gr font-bold">
                    {{ article.average_cost | moneyFilter }} {{user.currency_code}}
                  </div>
                  <div class="text-blue-500 mt-2">Prix d'achat</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-tabs v-model="activeName" class="mt-6" @tab-click="handleTab">
        <el-tab-pane label="Stock" name="STOCK">
          <stock
            @total-item="FETCH_TOTAL_STOCKHAND"
            :articleId="this.$route.params && this.$route.params.id"
            v-if="activeName === 'STOCK'"
            class="mt-2"
          />
        </el-tab-pane>
        <el-tab-pane label="Mouvement de stock" name="MOVEMENT">
          <movement v-if="activeName === 'MOVEMENT'" class="mt-2" />
        </el-tab-pane>
        <el-tab-pane label="Ajustements" name="ADJUSTEMENT">
          <adjustement v-if="activeName === 'ADJUSTEMENT'" />
        </el-tab-pane>
        <el-tab-pane label="Paramètre" name="SETTING">
          <div class="flex flex-col justify-evenly mt-2">
            <div class="flex mb-3">
              <span class="mr-2 w-1/3 mb-1 text-gray-500"
                >Article à vendre (devis,commande, facture):</span
              >
              <span v-if="article.has_sale" class="text-base font-medium">
                Oui
              </span>
              <span v-if="!article.has_sale" class="text-base font-medium">
                Non
              </span>
            </div>
            <div class="flex mb-3">
              <span class="mr-2 w-1/3 mb-1 text-gray-500"
                >Article à vendre aux PV:</span
              >
              <span v-if="article.has_pos" class="text-base font-medium">
                Oui
              </span>
              <span v-if="!article.has_pos" class="text-base font-medium">
                Non
              </span>
            </div>
            <div class="flex mb-3">
              <span class="mr-2 w-1/3 mb-1 text-gray-500"
                >Article à achater :</span
              >
              <span v-if="article.has_purchage" class="text-base font-medium">
                Oui
              </span>
              <span v-if="!article.has_purchage" class="text-base font-medium">
                Non
              </span>
            </div>
            <div class="flex mb-3">
              <span class="mr-2 w-1/3 mb-1 text-gray-500"
                >Possiblité de faire un inventaire sur l'article :</span
              >
              <span v-if="article.has_inventory" class="text-base font-medium">
                Oui
              </span>
              <span v-if="!article.has_inventory" class="text-base font-medium">
                Non
              </span>
            </div>
            <div class="flex mb-3">
              <span class="mr-2 w-1/3 mb-1 text-gray-500"
                >Verifier le stock disponible avant de faire une vente:</span
              >
              <span v-if="article.check_stock" class="text-base font-medium">
                Oui
              </span>
              <span v-if="!article.check_stock" class="text-base font-medium">
                Non
              </span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getArticle } from "@/api/article";
import { fetchTotalStockOnHand } from "@/api/stock";
import { toThousandFilter } from "@/Filters";
import { parseTime } from "@/utils";
import Movement from "./movement.vue";
import Adjustement from "./adjustement.vue";
import Stock from "./stock.vue";
import Active from "@/components/active";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";

export default {
  name: "FicheArticle",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { Movement, Adjustement, Stock, Active, Loading },

  data() {
    return {
      activeName: "STOCK",
      stockOnHand: 0,
      loading: false,
      article: {
        date_create: "",
        date_update: "",
        active: false,
        article_id: "",
        article_name: "",
        average_cost: 0,
        barcode: "",
        category_id: 70,
        category_value: "",
        check_stock: false,
        group_id: null,
        group_name: "",
        has_inventory: false,
        has_pos: false,
        has_purchage: false,
        has_sale: false,
        image_url: false,
        image_url_large: false,
        image_url_min: false,
        is_active: false,
        remark: "",
        selling_price: 0,
        sku: "",
        stock_max: 0,
        stock_min: 0,
        uom_id: 0,
        uom_value: "",
        warning_terms: "",
      },
    };
  },
  computed: {...mapGetters(["user"]),},
  mounted() {
    this.initData();
  },
  methods: {
    handleTab(obj) {
      const name = obj.name;
      console.log(name);
      switch (name) {
        case "PRICE":
          break;

        case "STOCK":
          break;

        case "MOVEMENT":
          break;
        case "ADJUSTMENT":
          break;
        case "SETTING":
          break;
      }
    },
    initData() {
      this.loading = true;
      setTimeout(() => {
        this.FETCH_ARTICLE();
        this.FETCH_TOTAL_STOCKHAND();
        this.loading = false;
      }, 1.5 * 1000);
    },
    async FETCH_ARTICLE() {
      const id = this.$route.params && this.$route.params.id;
      await getArticle(id).then((res) => {
        this.article = Object.assign({}, res.data);
      });
    },
    async FETCH_TOTAL_STOCKHAND() {
      const id = this.$route.params && this.$route.params.id;
      await fetchTotalStockOnHand(id).then((res) => {
        this.stockOnHand = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
