<template>
  <div class="flex flex-col mt-6">
    <div class="customer-white">
      <skeleton-list :row="8" v-if="listLoading" />
      <el-table :data="items" v-else style="width: 100%">
        <el-table-column prop="date" label="DATE Création" width="170">
          <template slot-scope="scope">
            <span> {{ scope.row.created_at }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="numero" label="NUMERO" width="150">
          <template slot-scope="{ row }">
            <span class="text-blue">{{ row.number }}</span>
          </template>
        </el-table-column>
        <el-table-column label="STATUT" width="150" align="left">
          <template slot-scope="{ row }">
            <Status :name="row.status" />
          </template>
        </el-table-column>
        <el-table-column width="215" label="RAISON">
          <template slot-scope="{ row }">
            <span>{{ row.raison }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150" label="NOTE">
          <template slot-scope="{ row }">
            <span>{{ row.note }}</span>
          </template>
        </el-table-column>

        <el-table-column label="quantité AVANT " align="right">
          <template slot-scope="{ row }">
            <span>{{ row.quantity_before }}</span>
          </template>
        </el-table-column>
        <el-table-column label="QUANTITÉ AJUSTÉE " align="right">
          <template slot-scope="{ row }">
            <span>{{ row.quantity_adjusted }}</span>
          </template>
        </el-table-column>
        <el-table-column label="QUANTITÉ APRÈS " align="right">
          <template slot-scope="{ row }">
            <span>{{ row.quantity_after }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.page > 1"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="FETCH_ADJUSTEMENT_STOCK()"
      />
    </div>
  </div>
</template>

<script>
import { fetchAdjustmentArticleStock, deleteAdjustement } from "@/api/stock";

import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import SkeletonList from "@/components/Skeleton/list";
export default {
  name: "Adjustement-Movement",
  components: { Pagination, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      categories: [],
      types: [],
      items: [],
      listLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 10,
        sort: "desc",
        sortDir: "createdAt",
      },
    };
  },
  mounted() {
    this.FETCH_ADJUSTEMENT_STOCK();
  },
  methods: {
    async FETCH_ADJUSTEMENT_STOCK() {
      const id = this.$route.params && this.$route.params.id;
      this.listLoading = true;
      await fetchAdjustmentArticleStock(this.listQuery, id)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    HANDLE_DELETE_ADJUSTEMENT(id) {
      this.listLoading = true;
      deleteAdjustement(id)
        .then(() => {
          setTimeout(() => {
            const index = this.items.findIndex((el) => el.id === id);
            this.items.splice(index, 1);
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch(() => {
          console.log("error");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
