import { render, staticRenderFns } from "./adjustement.vue?vue&type=template&id=6f1e56e4&scoped=true&"
import script from "./adjustement.vue?vue&type=script&lang=js&"
export * from "./adjustement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f1e56e4",
  null
  
)

export default component.exports