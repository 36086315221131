<template>
  <div class="flex flex-col">
    <div class="flex flex-row mb-4 items-center">
      <el-select
        class="mr-2 flex-1"
        placeholder="Type"
        v-model="listQuery.type"
        @clear="clearType()"
        clearable
        @change="fetchMovementStock()"
      >
        <el-option
          v-for="(item, index) in types"
          :key="index"
          :value="item.name"
          :label="item.libelle"
        >
        </el-option>
      </el-select>

      <el-select
        placeholder="Categorie"
        class="mr-2 flex-1"
        v-model="listQuery.category"
        clearable
        @change="fetchMovementStock()"
        @clear="clearCategory()"
      >
        <el-option
          v-for="(item, index) in categories"
          :key="index"
          :value="item.id"
          :label="item.name"
        >
        </el-option>
      </el-select>
      <el-date-picker
        class="mr-2 w-1/7"
        v-model="listQuery.start"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="Date debut"
      >
      </el-date-picker>
      <el-date-picker
        class="w-1/7 mr-2"
        v-model="listQuery.end"
        type="date"
        value-format="yyyy-MM-dd"
        placeholder="Date fin"
      >
      </el-date-picker>

      <el-button @click="fetchMovementStock()">Rechercher</el-button>
      <el-button class="mr-2">Filtre</el-button>

      <el-button>Exporter</el-button>
    </div>
    <div class="customer-white">
      <skeleton-list :row="8" v-if="listLoading" />
      <el-table :data="items" v-else style="width: 100%">
        <el-table-column prop="date" label="DATE" width="180">
          <template slot-scope="scope">
            <span> {{ scope.row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="RÉFERENCE" width="170">
          <template slot-scope="scope">
            <span class="text-blue">{{ scope.row.reference }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="category"
          label="CATEGORIE"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column align="left" width="100" label="STATUT">
          <template slot-scope="{ row }">
            <Status :name="row.status" />
          </template>
        </el-table-column>
        <el-table-column prop="type" align="left" label="TYPE" min-width="130">
        </el-table-column>

        <el-table-column
          prop="quantity_in"
          label="QUANTITÉ"
          width="120"
          align="right"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.quantity }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="purchase_price"
          label="P. SORTIE "
          width="125"
          align="right"
        >
          <template slot-scope="{ row }">
            <span>{{ row.sale_price | moneyFilter }} {{user.currency_code}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="sale_price"
          width="135"
          label="P. D'ENTRÉE "
          align="right"
        >
          <template slot-scope="{ row }">
            <span>{{ row.purchase_price | moneyFilter }} {{user.currency_code}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="stock_on_hand"
          label="N.STOCK"
          align="right"
          width="110"
        >
          <template slot-scope="{ row }">
            <span>{{ row.stock_on_hand }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="flex justify-end">
      <pagination
        v-show="listQuery.total > 0"
        :total="listQuery.total"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.size"
        @pagination="fetchMovementStock()"
      />
    </div>
  </div>
</template>

<script>
import {
  fetchTypesMovementStock,
  fetchMovementStockForArticle,
} from "@/api/stock";

import { getCategories } from "@/api/article";
import Pagination from "@/components/Pagination";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";

export default {
  name: "Article-Movement",
  components: { Pagination, Status, SkeletonList },
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      categories: [],
      types: [],
      items: [],
      listLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 10,
        start: undefined,
        end: undefined,
        type: undefined,
        category: undefined,
        sort: "desc",
        sortDir: "createdAt",
      },
    };
  },
  mounted() {
    this.fetchMovementStock();
    this.fetchCategories();
    this.fetchTypes();
  },
    computed :{
        ...mapGetters(["user"])
    },
  methods: {
    async fetchCategories() {
      await getCategories()
        .then((res) => {
          this.categories = res.data;

          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async fetchTypes() {
      await fetchTypesMovementStock()
        .then((res) => {
          this.types = res.data;
          // console.log(this.categories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTypeLibelle(name) {
      const item = this.types.find((v) => v.name === name);

      return item && item.libelle;
    },

    async fetchMovementStock() {
      const id = this.$route.params && this.$route.params.id;
      this.listLoading = true;
      await fetchMovementStockForArticle(this.listQuery, id)
        .then((res) => {
          setTimeout(() => {
            const items = res.data.items;
            this.items = items.map((v) => {
              const nametype = this.getTypeLibelle(v.type);
              this.$set(v, "type", nametype);
              return v;
            });
            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
            console.log(this.items);
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearCategory() {
      this.listQuery.category = undefined;
    },
    clearType() {
      this.listQuery.type = undefined;
    },
  },
};
</script>

<style lang="scss" scoped></style>
